.ssidebar {
	width: 70px;
	height: calc(100dvh - 32px);
	border-radius: var(--radius-lg);
	box-shadow: var(--shadow-md);
	background-color: var(--bg-light);
	padding: 20px 0;
	text-align: center;
}
.ssidebar.dark {
	background-color: var(--d-bg-light);
}

.ssidebar h1 {
	font-size: var(--text-xl);
	color: var(--color-dark);
	margin-bottom: 44px;
}
.ssidebar.dark h1 {
	color: var(--d-color-dark);
}

.ssidebar ul {
	height: calc(100dvh - 140px);
	overflow-y: auto;
	padding: 0;
}

.ssidebar ul li a {
	width: 40px;
	height: 40px;
	display: block;
	color: var(--color-secondary);
	padding: var(--padding-sm);
	margin: 4px auto;
	border-radius: var(--radius-md);
	transition: 0.1s;
}
.ssidebar.dark ul li a {
	color: var(--d-color-secondary);
}
.ssidebar ul li a:hover {
	background-color: var(--bg-secondary);
	color: var(--color-light);
}
.ssidebar.dark ul li a:hover {
	background-color: var(--d-bg-secondary);
}
.ssidebar ul li a.active {
	background-color: var(--bg-secondary);
	color: var(--color-light);
}
.ssidebar.dark ul li a.active {
	background-color: var(--d-bg-icon);
	color: var(--color-primary);
}

.slogout {
	position: absolute;
	bottom: 24px;
	left: 30px;
}

.slogout a {
	color: var(--color-danger) !important;
}

.slogout a:hover {
	background-color: var(--color-danger) !important;
	color: var(--color-light) !important;
}
