.add-modal-wrapper {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9;
	width: 100dvw;
	height: 100dvh;
	background-color: var(--bg-opacity);
}
.add-modal-wrapper.dark {
	background-color: var(--d-bg-opacity);
}

.add-modal {
	float: right;
	width: 0;
	height: 100%;
	background-color: var(--bg-light);
}
.add-modal-wrapper.dark .add-modal {
	background-color: var(--d-bg-light);
}

.modal-top {
	width: 100%;
	height: 110px;
	background-color: var(--bg-neutral);
	text-align: right;
	padding: 24px 16px 27px 32px;
}
.add-modal-wrapper.dark .modal-top {
	background-color: var(--d-bg-neutral);
}

.modal-top button {
	cursor: pointer;
	border: none;
	background: none;
}

.modal-top h4 {
	margin-top: 20px;
	text-align: left;
	font-size: var(--text-modal);
	color: var(--color-primary);
}

/* bottom */
.modal-bottom {
	height: calc(100% - 110px);
	padding: 24px 32px;
	overflow-y: auto;
}

.input-wrapper.modal-form {
	margin-bottom: 24px;
}

.input-wrapper.modal-form label {
	color: var(--color-secondary);
}

/* select */
.input-wrapper.modal-form .select .ant-select-selector {
	border-radius: var(--radius-md);
	border: var(--border-secondary);
	font-size: var(--text-lg) !important;
}
.input-wrapper.modal-form .select .ant-select-selection-placeholder {
	color: var(--color-modal-placeholder);
	opacity: 0.5;
}
/* input */
.input-wrapper.modal-form .input {
	font-size: var(--text-lg);
	font-weight: var(--weight-thin);
	border: var(--border-secondary);
	border-radius: var(--radius-md);
	padding: 8px, 16px, 8px, 16px;
}
.input-wrapper.modal-form .input::placeholder {
	color: var(--color-modal-placeholder);
	opacity: 0.5;
}

/* button group */
.modal-btn-group {
	width: 100%;
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.modal-btn-group .primary-btn {
	width: 130px;
	padding: 8px 24px;
}

/* error */
.input-wrapper.modal-form.regular.error {
	position: relative;
}
.input-wrapper.modal-form.error svg {
	color: var(--color-danger);
}
.input-wrapper.modal-form.regular.error svg {
	position: absolute;
	right: 12px;
	top: calc(50% + 4px);
	transform: translateY(-50%);
}
.input-wrapper .validation-field span {
	color: var(--color-danger) !important;
	font-weight: var(--weight-thin);
	font-size: var(--text-md);
}
.input-wrapper.modal-form.error .input {
	border: var(--border-danger);
	outline: none;
}
.input-wrapper.modal-form.error .ant-select-selector {
	border: var(--border-danger);
	border-radius: var(--radius-md);
}

/* animation */
.fade-in {
	animation: fade-in 0.3s ease-out;
	width: 350px;
}

.fade-out {
	animation: fade-out 0.3s ease-out;
	width: 0;
}

@keyframes fade-in {
	0% {
		width: 0;
	}
	100% {
		width: 350px;
	}
}

@keyframes fade-out {
	0% {
		width: 350px;
	}
	100% {
		width: 0;
	}
}
