@font-face {
	font-family: SFProRegular;
	src: url("./assets/fonts/Regular.OTF");
}
@font-face {
	font-family: SFProBold;
	src: url("./assets/fonts/Bold.OTF");
}

:root {
	/* colors */
	--color-primary: #2196f3;
	--color-secondary: #424242;
	--color-dark: #212121;
	--color-accent: #757575;
	--color-success: #4caf50;
	--color-warning: #ffc107;
	--color-danger: #f44336;
	--color-light: #fff;
	--color-modal-placeholder: #bdbdbd;

	/* bg */
	--bg-primary: #2196f3;
	--bg-secondary: #64b5f6;
	--bg-accent: #9e9e9e;
	--bg-neutral: #fafafa;
	--bg-danger: #f44336;
	--bg-success: #4caf50;
	--bg-success-icon: #e8f5e9;
	--bg-light: #ffffff;
	--bg-disabled: #e0e0e0;
	--bg-opacity: rgba(158, 158, 158, 0.6);
	--bg-icon: #e3f2fd;
	--bg-icon-warning: #fff8e1;
	--bg-btn-hover: #e3f2fd;
	--bg-pagination-btn: #eee;
	--bg-danger-opacity: #fbd9df;

	/* shadow */
	--shadow-lg: 0px 1px 4px 1px #0000000f;
	--shadow-md: 0px 1px 2px 0px #0000000f;
	--shadow-dark: 0px 1px 4px 1px #201d1d8b;

	/* border */
	--border-primary: 1px solid #e0e0e0;
	--border-secondary: 1px solid #bdbdbd;
	--border-dark: 1px solid #424242;
	--border-danger: 1px solid #f44336;
	--border-success: 1px solid #4caf50;
	--border-info: 1px solid #2196f3;
	--border-info-hover: 1px solid #64b5f6;

	/* fonts */
	--text-xxl: 28px;
	--text-xl: 24px;
	--text-modal: 18px;
	--text-lg: 16px;
	--text-md: 14px;
	--text-sm: 12px;

	/* padding */
	--padding-xxl: 30px;
	--padding-xl: 24px;
	--padding-lg: 16px;
	--padding-md: 12px;
	--padding-sm: 8px;

	/* radius */
	--radius-lg: 8px;
	--radius-btn: 7px;
	--radius-md: 6px;
	--radius-sm: 4px;

	/* font weight */
	--weight-bold: 600;
	--weight-thin: 400;

	/* dark mode */
	--d-color-secondary: #9da9bb;
	--d-color-input: #d8e2ef;
	--d-color-dark: #2c7be5;
	--d-color-accent: #5e6e82;
	--d-color-option-selected: #d8e2ef;
	--d-color-select-icon: #4d5969;
	--d-bg-primary: #2c7be5;
	--d-bg-secondary: #162231;
	--d-bg-input: #0b1727;
	--d-bg-neutral: #0b1727;
	--d-bg-light: #121e2d;
	--d-bg-opacity: rgba(22, 34, 49, 0.8);
	--d-bg-icon: #232e3c;
	--d-border-primary: 1px solid #344050;
	--d-border-invisible: 1px solid transparent;
}

body {
	font-family: SFProRegular;
	font-weight: 500;
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button {
	margin: 0;
	padding: 0;
	letter-spacing: 0.5px;
}

ul {
	list-style: none;
}
