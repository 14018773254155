/* input wrapper */
.input-wrapper {
	display: flex;
	flex-direction: column;
}

/* label */
.input-wrapper label {
	color: var(--color-secondary);
	font-size: var(--text-lg);
	margin-bottom: var(--padding-sm);
}
.input-wrapper.dark label {
	color: var(--d-color-secondary) !important;
}

/* input */
.input-wrapper input {
	width: 100%;
	height: 40px;
	border-radius: var(--radius-sm);
	border: var(--border-primary);
	padding: var(--padding-lg);
	font-weight: var(--weight-thin);
	font-size: var(--text-md);
	color: var(--color-secondary);
}
.input-wrapper.dark input {
	border: var(--d-border-primary) !important;
	background-color: var(--d-bg-input);
	color: var(--d-color-input);
	outline: 0;
}
.input-wrapper.dark input:focus {
	border: var(--border-info) !important;
}
.input-wrapper.dark .select input {
	border: none !important;
}
.input-wrapper input.date {
	padding: 0 14px;
}
.input-wrapper input::placeholder,
.input-wrapper .desc-input::placeholder {
	color: var(--color-modal-placeholder);
}
.input-wrapper.pay-modal.dark {
	text-align: left !important;
}
.input-wrapper.pay-modal.dark label {
	color: var(--d-color-dark);
}
.input-wrapper.pay-modal.dark input::placeholder {
	color: var(--d-color-secondary);
}

/* textarea */
.input-wrapper .desc-input {
	width: 100%;
	min-height: 100px;
	/* height: 100px !important; */
	max-height: 200px;
	border-radius: var(--radius-sm);
	border: var(--border-primary);
	padding: var(--padding-sm) var(--padding-lg);
	font-weight: var(--weight-thin);
	font-size: var(--text-md);
	color: var(--color-secondary);
}
.input-wrapper.dark .desc-input {
	border: var(--d-border-primary);
	outline: 0;
	color: var(--d-color-input);
	background-color: var(--d-bg-input);
}
.input-wrapper.dark .desc-input:focus {
	border: var(--border-info) !important;
}

/* select */
.input-of-two {
	display: flex;
	flex-direction: row;
}
.input-of-two-first .ant-select-selector {
	width: 100px !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.input-of-two-first svg {
	color: var(--color-modal-placeholder) !important;
}
.input-of-two-second {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.svgMargin .thisSvg {
	margin-right: 100px;
}

.input-wrapper .select .ant-select-selector {
	border-radius: var(--radius-sm);
	border: var(--border-primary);
	font-size: var(--text-md) !important;
	font-weight: var(--weight-thin) !important;
	height: 40px;
	font-family: SFProRegular;
	padding-left: 16px !important;
	line-height: 1 !important;
}
.input-wrapper .select .anticon-close-circle {
	margin-left: 0px !important;
	margin-top: 0px !important;
	background: var(--bg-light) !important;
}
.input-wrapper .select input {
	margin-top: 4px !important;
	padding-left: 2.5px !important;
	margin-left: 2.5px !important;
}
.input-wrapper .select .ant-select-selection-placeholder {
	height: 40px;
	line-height: 40px;
	color: var(--color-modal-placeholder);
}
.input-wrapper .select .ant-select-selection-item {
	height: 40px;
	line-height: 40px;
	color: var(--color-secondary);
}
.option-shrink div span {
	transition: 0.1s;
}
.option-shrink:hover span:first-child {
	font-size: 0;
}
/*  */
.input-wrapper.dark .select .ant-select-selector {
	border-radius: var(--radius-sm);
	border: var(--d-border-primary) !important;
	font-size: var(--text-md) !important;
	font-weight: var(--weight-thin) !important;
	height: 40px;
	font-family: SFProRegular;
	padding-left: 16px !important;
	line-height: 1 !important;
	background-color: var(--d-bg-input);
	color: var(--color-light) !important;
}
.ant-select-selector:hover {
	border: var(--border-secondary) !important;
}

.input-wrapper.dark .select .anticon-close-circle {
	background: var(--d-bg-neutral) !important;
}
.input-wrapper.dark
	.select
	.ant-select-selector
	.ant-select-selection-search-input:focus {
	box-shadow: none;
}
.input-wrapper.dark .ant-select-selector:focus-within {
	border: var(--border-info) !important;
	box-shadow: none !important;
}
.ant-select-div {
	color: var(--color-light);
}
.input-wrapper.dark .select input {
	margin-top: 4px !important;
	padding-left: 2.5px !important;
	margin-left: 2.5px !important;
}
.input-wrapper.dark .select .ant-select-selection-placeholder {
	height: 40px;
	line-height: 40px;
	color: var(--color-modal-placeholder);
}
.input-wrapper.dark .select .ant-select-selection-item {
	height: 40px;
	line-height: 40px;
	color: var(--d-color-option-selected);
}
.ant-select-arrow {
	color: var(--d-color-select-icon) !important;
	margin-right: -1px !important;
}
.ant-select-clear {
	line-height: 0 !important;
	background-color: var(--d-bg-input) !important;
	opacity: 1;
	color: var(--d-color-select-icon) !important;
}
.ant-select-dropdown:has(.dark) {
	background-color: var(--d-bg-input);
}
.dark .ant-select-item.ant-select-item-option:hover {
	background: var(--bg-light) !important;
}
.dark .ant-select-item-option-content div {
	color: var(--color-light);
}
/*  */
.pagination-limit {
	width: 200px;
	margin-top: 10px !important;
}
.pagination-limit .select .ant-select-selector {
	height: 30px !important;
	line-height: 30px !important;
}
.pagination-limit .select .ant-select-selection-placeholder {
	height: 30px !important;
	line-height: 30px !important;
}
.pagination-limit .select .ant-select-selection-item {
	height: 30px !important;
	line-height: 30px !important;
}
.option-shrink div span {
	transition: 0.1s;
}
.option-shrink:hover span:first-child {
	font-size: 0;
}

/* ant modal */
.dark .ant-modal-content {
	background-color: var(--d-bg-icon) !important;
}
.dark .ant-modal-confirm-title {
	color: var(--d-color-input) !important;
}
.dark .ant-btn-default {
	background: var(--d-bg-icon);
	color: var(--color-light);
	border: var(--border-secondary);
}
.dark .ant-btn-default:hover {
	background: var(--d-bg-icon) !important;
	color: var(--color-light) !important;
	border: var(--border-secondary) !important;
}
.ant-btn-primary,
.ant-btn-success,
.ant-btn-dangerous {
	background: var(--d-bg-primary) !important;
	color: var(--color-light) !important;
	border: none !important;
	box-shadow: none;
}
.ant-btn-primary:hover,
.ant-btn-success:hover,
.ant-btn-dangerous:hover {
	border: none !important;
	background: var(--bg-secondary) !important;
	color: var(--color-light) !important;
}
.dark .ant-btn-primary:hover,
.dark .ant-btn-success:hover,
.dark .ant-btn-dangerous:hover {
	border: none !important;
	background: var(--d-bg-secondary) !important;
	color: var(--color-light) !important;
}

/* quantity */
.quantityWrapper {
	margin: 0;
	padding: 0;
	width: 120px;
	display: flex;
	justify-content: space-around;
}
.quantityInput {
	width: 45px;
	text-align: center;
	outline: none;
	height: 24px;
	line-height: 24px;
}
.dark .quantityInput {
	border: var(--d-border-invisible);
	color: var(--color-light);
	border-radius: var(--radius-sm);
	background-color: var(--d-bg-icon);
}
.quantityBtn {
	height: 24px;
	background-color: var(--bg-primary);
	color: var(--color-light);
	line-height: 10px;
	outline: none;
	border: none;
	border-radius: var(--radius-sm);
	padding: 0 8px;
	margin: 0;
	transition: 0.2s;
}
.dark .quantityBtn {
	background-color: var(--d-bg-primary);
}
.quantityBtn:hover {
	background-color: var(--bg-secondary);
}
.dark .quantityBtn:hover {
	background-color: var(--d-bg-secondary);
}

/* button */
.primary-btn {
	font-size: var(--text-md);
	color: var(--color-light);
	border-radius: var(--radius-sm);
	background-color: var(--bg-primary);
	cursor: pointer;
	border: none;
	padding: 8px 32px;
	height: 40px;
	transition: 0.1s;
}
.primary-btn.dark {
	background-color: var(--d-bg-primary);
}
.primary-btn.small {
	padding: 8px;
}
.primary-btn.low-height {
	height: 30px;
	line-height: 1;
	margin: 10px;
}
.primary-btn.sell {
	margin: 30px 0;
	float: right;
	width: 130px;
}
.primary-btn:hover {
	background-color: var(--bg-secondary);
}
.primary-btn.dark:hover {
	background-color: var(--d-bg-input);
}
.download-btn {
	border: none;
	background: none;
	outline: none;
	color: var(--color-secondary);
}
.download-btn.accordion {
	margin-left: 20px;
}
.back-btn {
	color: var(--color-secondary);
	outline: none;
	background: none;
	border: none;
	margin-bottom: 20px;
}
.back-btn.dark {
	color: var(--d-color-secondary);
}
.back-btn svg {
	margin: 0;
	padding: 0;
	margin-top: -5px;
}
.primary-btn:disabled {
	background: var(--bg-disabled);
	cursor: not-allowed;
}
.primary-btn.dark:disabled {
	background: var(--d-bg-icon);
}
.secondary-btn {
	font-size: var(--text-md);
	color: var(--color-secondary);
	border-radius: var(--radius-sm);
	background-color: var(--bg-light);
	cursor: pointer;
	border: none;
	padding: 8px 32px;
	height: 40px;
	transition: 0.1s;
	border: var(--border-secondary);
}
.secondary-btn.dark {
	background: transparent;
	color: var(--color-light);
	border: var(--border-secondary);
}
.secondary-btn:hover {
	border: var(--border-primary);
}
.accordion-delete__btn {
	font-size: var(--text-md);
	color: var(--color-light);
	background-color: var(--bg-danger);
	border-radius: var(--radius-sm);
	cursor: pointer;
	border: none;
	padding: 2px 18px;
	transition: 0.1s;
}
.accordion-delete__btn.extra {
	background-color: var(--bg-primary);
}
.dark .accordion-delete__btn {
	background-color: var(--d-bg-icon);
}
.accordion-delete__btn:hover {
	filter: brightness(1.3);
}

/* info */
.info-wrapper {
	width: 100%;
	height: 70px;
}
.info-item {
	width: 270px;
	height: 70px;
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
	background-color: var(--bg-light);
	border-radius: var(--radius-lg);
	box-shadow: var(--shadow-md);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}
.info-item.dark {
	background-color: var(--d-bg-light);
}
.info-item h3 {
	font-size: var(--text-lg);
	color: var(--color-secondary);
}
.info-item.dark h3 {
	color: var(--d-color-secondary);
}
.info-item h4 {
	font-size: var(--text-sm);
	color: var(--color-accent);
}
.info-item.dark h4 {
	color: var(--d-color-accent);
}
.info-item span {
	width: 36px;
	height: 36px;
	display: grid;
	place-items: center;
	background-color: var(--bg-icon);
	border-radius: 50%;
}
.info-item.dark span {
	background-color: var(--d-bg-icon);
}
.info-item span svg {
	color: var(--color-primary);
}

/* filter */
.filter-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 12px;
	padding-right: 50px;
	margin-bottom: 20px;
}
.filter-wrapper.report {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}
.filter-wrapper.good .input-wrapper {
	width: 200px;
}
/* select */
.filter-wrapper .input-wrapper .select .ant-select-selector {
	height: 30px;
	line-height: 30px;
}
.filter-wrapper .input-wrapper .select .ant-select-selection-placeholder {
	height: 30px;
	line-height: 30px;
	color: var(--d-color-accent);
}
.filter-wrapper .input-wrapper .select .ant-select-selection-item {
	height: 30px;
	line-height: 30px;
}
.filter-wrapper .input-wrapper .select .ant-select-selection-search-input {
	margin-top: 0 !important;
}
.and-select-item-option.dark {
	color: var(--color-light) !important;
}
.ant-select-dropdown:has(.dark) .ant-select-item-option-active {
	background: var(--d-bg-icon) !important;
}
.ant-select-dropdown:has(.dark) .ant-select-item-option-selected {
	background-color: var(--d-bg-opacity) !important;
}
/* date picker */
.filter-wrapper.dark .input-wrapper .date-picker.ant-picker {
	height: 30px;
	background: var(--d-bg-input);
}
.filter-wrapper.dark .input-wrapper .ant-picker-range {
	border: var(--d-border-primary);
}
.filter-wrapper.dark .input-wrapper .ant-picker-input input {
	border: none !important;
	color: var(--d-color-secondary) !important;
}
.filter-wrapper.dark .input-wrapper .date-picker.ant-picker input::placeholder {
	color: var(--d-color-accent) !important;
}
.filter-wrapper.dark .input-wrapper .date-picker.ant-picker svg {
	color: var(--d-color-secondary);
}
.filter-wrapper.dark .input-wrapper .date-picker.ant-picker .ant-picker-clear {
	background-color: var(--d-bg-input);
}
/* btn */
.filter-wrapper .filter-btn {
	font-size: var(--text-md);
	color: var(--color-light);
	border-radius: var(--radius-sm);
	background-color: var(--bg-primary);
	cursor: pointer;
	border: none;
	padding: 0 28px;
	height: 30px;
	transition: 0.1s;
}
.filter-btn:disabled {
	cursor: not-allowed;
}
.filter-wrapper.dark .filter-btn {
	background-color: var(--d-bg-neutral);
	color: var(--d-color-input);
	border: var(--d-border-primary);
}
.filter-wrapper .filter-btn:hover {
	background-color: var(--bg-secondary);
}
.filter-wrapper.dark .filter-btn:hover {
	background-color: var(--d-bg-secondary);
}
.filter-btn-group {
	width: 270px;
	display: flex;
	justify-content: space-between;
}

/* .filter-wrapper .input-wrapper .select .ant-select-selector {
	height: 35px;
	line-height: 1 !important;
}
.filter-wrapper .input-wrapper .select .ant-select-selection-placeholder {
	height: 35px;
	line-height: 1 !important;
}
.filter-wrapper .input-wrapper .ant-select-selection-item {
	height: 35px;
	line-height: 1 !important;
} */
/* .filter-wrapper .input-wrapper .select .ant-select-selector {
	border-radius: var(--radius-sm);
	border: var(--border-primary);
	font-size: var(--text-md) !important;
	font-weight: var(--weight-thin) !important;
	height: 35px;
	font-family: SFProRegular;
	padding-left: 16px !important;
	line-height: 1 !important;
}
.filter-wrapper .input-wrapper .select .anticon-close-circle {
	background: var(--color-light) !important;
}
.filter-wrapper .input-wrapper .select input {
	margin-top: 4px !important;
	padding-left: 2.5px !important;
	margin-left: 2.5px !important;
}
.filter-wrapper .input-wrapper .select .ant-select-selection-item {
	height: 35px;
	line-height: 35px;
	color: var(--color-secondary);
} */
/* datepicker--------------------------------------------------------------------------------------- */

/* .filter-wrapper.dark .input-wrapper .date-picker.ant-picker {
	background: var(--d-bg-input);
}
.ant-picker-input input,
.ant-picker-input input::placeholder,
.ant-picker-separator,
.ant-picker-suffix {
	color: var(--d-color-secondary) !important;
}
.ant-picker-clear {
	color: var(--d-color-secondary) !important;
	background-color: var(--d-bg-input) !important;
}
.filter-wrapper .filter-btn-group {
	display: flex;
	gap: 12px;
}
.filter-wrapper .filter-btn-group .filter-btn {
	padding: 4px 16px;
	border-radius: var(--radius-sm);
	border: var(--border-primary);
	background-color: var(--bg-light);
	color: var(--color-accent);
	font-weight: var(--weight-thin);
}
.filter-wrapper.dark .filter-btn-group .filter-btn {
	background-color: var(--d-bg-input);
}
.filter-wrapper .filter-btn-group .filter-btn:disabled {
	background: var(--bg-disabled);
	cursor: not-allowed;
}
.filter-wrapper.dark .filter-btn-group .filter-btn {
	background: var(--d-bg-icon);
} */

/* saerch */
.search-wrapper {
	/* position: sticky;
	z-index: 2;
	top: -1px; */
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	padding: 20px;
	background-color: var(--bg-light);
	border-radius: var(--radius-lg);
	box-shadow: var(--shadow-md);
}
.search-wrapper.dark {
	background-color: var(--d-bg-light);
}
.search-wrapper input {
	padding-right: 40px;
}
.search-wrapper.dark input {
	border: var(--d-border-invisible) !important;
}
.search-wrapper div:first-child {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 460px;
	gap: 20px;
}
.search-wrapper svg {
	position: absolute;
	left: 308px;
	top: 50%;
	transform: translateY(-50%);
	color: var(--color-secondary);
	cursor: pointer;
}
.search-wrapper.dark svg {
	color: var(--d-color-secondary);
}
.search-wrapper.table-m {
	border-radius: 0;
	border-top-left-radius: var(--radius-lg);
	border-top-right-radius: var(--radius-lg);
}

/* confirm modal */
.ant-modal-confirm-body-wrapper div:first-child {
	width: 250px;
	text-align: center;
}
.ant-modal-confirm-body-wrapper div:first-child span {
	font-size: var(--text-modal) !important;
	font-weight: var(--weight-bold);
}
.ant-modal-confirm-body-wrapper div:last-child {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.ant-modal-confirm-body-wrapper button.ant-btn-dangerous {
	background-color: var(--bg-danger);
	color: var(--color-light);
}

/* card */
.card-wrapper {
	width: 100%;
	padding: 20px 0;
	display: grid;
	gap: 20px;
}
.card-item {
	padding: 20px;
	border-radius: var(--radius-lg);
	background-color: var(--bg-light);
	box-shadow: var(--shadow-md);
	display: grid;
	grid-template-rows: auto auto;
}
.card-wrapper.dark .card-item {
	background-color: var(--d-bg-light);
}
.card-item-top {
	display: flex;
	justify-content: space-between;
}
.card-item-bottom {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.card-item-edit-holder {
	position: relative;
}
.card-item-edit-holder button {
	border: none;
	background: none;
	cursor: pointer;
}
.dark .card-item-edit-holder button {
	color: var(--d-color-secondary);
}
.card-item-edit-wrapper {
	position: absolute;
	z-index: 1;
	top: 32px;
	right: -20px;
	width: 130px;
	height: 80px;
	background-color: var(--bg-light);
	border-radius: var(--radius-lg);
	border: var(--border-primary);
	display: grid;
	grid-template-rows: auto auto;
	overflow: hidden;
}
.card-wrapper.dark .card-item-edit-wrapper {
	border: var(--d-border-primary);
	background-color: var(--d-bg-light);
}
.card-item-edit-wrapper.top {
	top: -90px;
}
.card-item-edit-wrapper button {
	display: grid;
	grid-template-columns: 70px auto;
	place-items: center;
	padding: 8px 12px;
	width: 100%;
	border: none;
	outline: none;
	background-color: transparent;
	font-weight: var(--weight-thin);
	font-size: var(--text-md);
	color: var(--color-secondary);
	transition: 0.1s;
}
.card-wrapper.dark .card-item-edit-wrapper button {
	color: var(--d-color-secondary);
}
.card-item-edit-wrapper button:hover {
	background-color: var(--bg-opacity);
}
.card-wrapper.dark .card-item-edit-wrapper button:hover {
	background-color: var(--d-bg-opacity);
}

/* grid cell */
.card-wrapper.grid {
	grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

/* ant delete confirm modal */
.ant-modal-confirm-title .delete-modal-span span,
.ant-modal-confirm-title .confirm-modal-span span {
	color: var(--d-color-dark);
}
/* .ant-modal-confirm-title .delete-modal-span span {
	color: var(--color-danger);
}
.ant-modal-confirm-title .confirm-modal-span span {
	color: var(--color-success);
} */

/* mini modal */
.mini-modal {
	position: absolute;
	top: 90px;
	left: 20px;
	z-index: 1;
	width: 100%;
	height: auto;
	max-height: 200px;
	padding: 10px;
	overflow-y: auto;
	border: var(--border-secondary);
	background-color: var(--bg-light);
	border-radius: var(--radius-lg);
	border-top-left-radius: 0;
	color: var(--color-accent);
	font-size: var(--text-md);
}
.mini-modal.dark {
	background-color: var(--d-bg-light);
	border: var(--d-border-primary);
}
.mini-modal h5 {
	font-size: var(--text-md) !important;
	border-bottom: var(--border-secondary);
	margin-bottom: 5px;
	padding-bottom: 5px;
}
.mini-modal h5:last-child {
	border-bottom: none;
}
.mini-modal h4 {
	font-size: var(--text-lg) !important;
}
.mini-modal.top {
	top: 50px;
	transform: translateY(-100%);
	border-radius: var(--radius-lg);
	border-bottom-left-radius: 0;
}

/* table */
div.ant-d-table {
	/* it was 122 */
	width: calc(100dvw - 129px);
}
div.ant-d-table .ant-table {
	overflow: hidden;
	border-radius: 0;
	border-bottom-left-radius: var(--radius-lg);
	border-bottom-right-radius: var(--radius-lg);
}
.table-img-col {
	overflow: hidden;
	width: 40px;
	height: 40px;
}
.ant-d-table img {
	transform: scale(1.5);
	cursor: pointer;
}
.stats-table-row {
	display: flex;
	flex-direction: row;
}

/* head */
div.ant-d-table .ant-table-thead .ant-table-cell {
	background-color: #eee;
	border-radius: 0 !important;
	font-family: SFProRegular;
}
.dark .ant-table-thead tr th,
.dark .ant-table-thead tr td {
	background-color: var(--d-bg-light) !important;
	color: var(--d-color-secondary) !important;
	border: none !important;
	border-bottom: var(--d-border-primary) !important;
}

/* body */
.dark .ant-table-tbody .ant-table-row {
	border: none !important;
	background-color: var(--d-bg-light) !important;
}
.dark .ant-table-tbody tr td {
	border: none !important;
}
.dark .ant-table-row,
.dark .ant-table-cell {
	background-color: unset !important;
}
div.ant-d-table .ant-table-tbody {
	color: var(--color-secondary);
	font-weight: var(--weight-bold);
	border: none !important;
}
.dark div.ant-d-table .ant-table-tbody {
	color: var(--d-color-secondary);
}
div.ant-d-table .ant-table-column-sort {
	background-color: transparent;
}
div.ant-d-table .ant-table-tbody .ant-table-cell {
	font-family: SFProRegular;
	font-weight: var(--weight-thin);
	padding: 6px !important;
	padding-left: 16px !important;
}
div.ant-d-table tr:nth-child(odd) {
	background-color: #fff;
}
div.ant-d-table tr:nth-child(even) {
	background-color: var(--bg-neutral);
}
.dark .ant-d-table tr:nth-child(even) {
	background-color: var(--d-bg-neutral) !important;
}
.table-item-edit-holder {
	position: relative;
	display: flex;
}
.dark .table-item-edit-holder button {
	color: var(--d-color-secondary);
}
.table-item-edit-holder button {
	border: none;
	background: none;
	cursor: pointer;
}
.table-item-edit-wrapper {
	position: absolute;
	z-index: 1;
	top: -70px;
	right: 40px;
	width: 160px;
	height: 110px;
	background-color: var(--bg-light);
	border: var(--border-primary);
	border-radius: var(--radius-lg);
	display: grid;
	grid-template-rows: auto auto auto;
}
.table-item-edit-wrapper.dark {
	border: var(--d-border-primary);
	background-color: var(--d-bg-light);
}
.table-item-edit-wrapper.extra {
	height: 60px;
	top: -35px;
}
.table-item-edit-wrapper.small {
	height: 80px !important;
	top: -40px;
	grid-template-rows: auto auto;
}
.table-item-edit-wrapper::after {
	content: "";
	width: 15px;
	height: 15px;
	background-color: var(--bg-light);
	border: var(--border-primary);
	border-left: transparent;
	border-bottom: transparent;
	position: absolute;
	bottom: 14px;
	right: -7.5px;
	transform: rotate(45deg);
	z-index: -1;
}
.table-item-edit-wrapper.dark::after {
	background-color: var(--d-bg-light);
	border: var(--d-border-primary);
	border-left: transparent;
	border-bottom: transparent;
}
.table-item-edit-wrapper button {
	display: flex;
	justify-content: space-between;
	place-items: center;
	padding: 6px 12px;
	height: 30px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	background-color: transparent;
	font-weight: var(--weight-thin);
	font-size: var(--text-md);
	color: var(--color-secondary);
	transition: 0.1s;
}
.table-item-edit-wrapper button:first-child {
	border-top-left-radius: var(--radius-btn);
	border-top-right-radius: var(--radius-btn);
}
.table-item-edit-wrapper button:last-child {
	border-bottom-left-radius: var(--radius-btn);
	border-bottom-right-radius: var(--radius-btn);
}
.table-item-edit-wrapper button:hover {
	background-color: var(--bg-opacity);
}
.table-item-edit-wrapper.dark button {
	color: var(--d-color-secondary);
}
.table-item-edit-wrapper.dark button:hover {
	background-color: var(--bg-opacity);
}
.table-item-edit-wrapper.dark button:hover {
	background-color: var(--d-bg-opacity);
}

/* modes */

.phosphor-icon {
	color: var(--d-color-secondary) !important;
}

/* error modal */
.error-modal__wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9;
	animation: fade-in 0.3s linear;
}

.error-modal {
	width: 400px;
	background-color: #fff;
	padding: 2rem;
	border-radius: 12px;
	text-align: center;
	position: fixed;
	top: 50%;
	right: 50%;
	transform: translateY(-50%) translateX(50%) rotateX(0deg);
	animation: move-in 0.3s linear;
}

.error-modal h1 {
	font-size: 24px;
	font-weight: 500;
}

.error-modal h4 {
	font-size: 16px;
	font-weight: 500;
}

.error-modal__btn {
	width: 100%;
	background-color: #6601a6;
	outline: none;
	border: none;
	font-weight: 500;
}

.error-modal__btn:hover {
	background-color: #6601a6;
}

/* css only dropdown on click */
.clickDropdownBtn {
	position: relative;
}
.clickDropdownBtn svg {
	pointer-events: none;
}
.clickDropdownBtn:focus .clickDropdownContent {
	visibility: visible;
	opacity: 1;
}
.clickDropdownBtn:active {
	transform: scale(0.98);
}
.clickDropdownBtn .clickDropdownContent {
	position: absolute;
	right: 40px;
	bottom: 0;
	z-index: 1;
	max-width: 400px;
	min-width: 200px;
	max-height: 50px;
	padding: 10px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease;
	background-color: var(--bg-light);
	color: var(--color-dark);
	box-shadow: var(--shadow-lg);
	border-radius: var(--radius-md);
	max-height: 50px;
	width: max-content;
	overflow: hidden;
}
.clickDropdownBtn .clickDropdownContent span svg {
	color: var(--color-warning);
	margin-top: -4px !important;
}
.dark .clickDropdownContent {
	background-color: var(--d-bg-icon);
	color: var(--d-color-input);
}

/*  */
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes move-in {
	0% {
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		opacity: 0;
	}
	50% {
		width: 200px;
		top: 10%;
		right: 10%;
		opacity: 0.5;
	}
	100% {
		width: 400px;
		top: 50%;
		right: 50%;
		opacity: 1;
	}
}

/*  */
.hide-modal__wrapper {
	visibility: hidden;
	opacity: 0;
	animation: fade-out 0.3s linear;
}
.hide-modal {
	width: 0;
	height: 0;
	top: 0;
	right: 0;
	opacity: 0;
	animation: move-out 0.3s linear;
}
@keyframes fade-out {
	from {
		visibility: visible;
		opacity: 1;
	}
	to {
		visibility: hidden;
		opacity: 0;
	}
}
@keyframes move-out {
	0% {
		width: 400px;
		top: 50%;
		right: 50%;
		opacity: 1;
	}
	50% {
		width: 200px;
		top: 10%;
		right: 10%;
		opacity: 0.5;
	}
	100% {
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		opacity: 0;
	}
}
