.card-item.goods {
	min-height: 120px;
}

.card-item-bottom.goods {
	display: grid;
	grid-template-columns: 1fr 50px;
}

.card-item-bottom__left {
	margin-top: 8px;
}

.card-item-bottom.goods .card-item__image {
	width: 50px;
	height: 50px;
	overflow: hidden;
	margin-top: 8px;
	border-radius: 4px;
	box-shadow: var(--shadow-lg);
}

.card-item-bottom.goods .card-item__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
}

.card-item.goods .card-item-top {
	position: relative;
}

.card-item.goods .card-item-top .card-item__image {
	position: absolute !important;
	right: 0;
	width: 50px;
	height: 50px;
	margin-top: 10px;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: var(--shadow-lg);
}

.card-item.goods .card-item-top .card-item__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.goods .card-item-top h3 {
	font-size: var(--text-modal);
	color: var(--color-secondary);
}

.dark .card-item-top h3 {
	color: var(--d-color-secondary);
}

.goods .card-item-bottom h3 {
	font-size: var(--text-md);
	color: var(--color-accent);
	font-weight: var(--weight-thin);
	margin-bottom: 10px;
}

.goods .card-item-bottom h4 {
	font-weight: var(--weight-thin);
	font-size: var(--text-sm);
	color: var(--color-accent);
}

.dark .card-item-bottom h3,
.dark .card-item-bottom h4 {
	color: var(--d-color-accent);
}

.drop-zone {
	width: 100%;
	height: 200px;
	padding: 20px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #cccccc;
	border: 1px dashed #d9d9d9;
	background-color: #f0f2f5;
}

.drop-zone--over {
	border-style: solid;
}

.drop-zone__input {
	display: none;
}

.drop-zone__thumb {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #cccccc;
	background-size: cover;
	position: relative;
}

.drop-zone__thumb::after {
	content: attr(data-label);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 0;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	text-align: center;
}
