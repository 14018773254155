.card-item.store {
	height: 90px;
}

.store .card-item-top h3 {
	font-size: var(--text-modal);
	color: var(--color-secondary);
}

.store.dark .card-item-top h3 {
	color: var(--d-color-secondary);
}

.store .card-item-bottom h4 {
	font-size: var(--text-sm);
	color: var(--color-accent);
}

.store.dark .card-item-bottom h4 {
	color: var(--d-color-accent);
}
