.login-wrapper {
	width: 100dvw;
	min-height: 100dvh;
	overflow: hidden;
	display: flex;
	flex-direction: row;
}

/* left */
.login-left {
	background: var(--bg-neutral);
	padding: 30px 180px;
	width: 100%;
	position: relative;
}

.login-left h1 {
	display: inline;
	color: var(--color-primary);
	font-size: var(--text-xxl);
	position: absolute;
	z-index: 1;
}

.login-left img {
	width: calc(100% - 20dvw);
	height: auto;
	object-fit: contain;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/* right */
.login-right {
	min-width: 460px !important;
	background: var(--bg-light);
	display: grid;
	place-items: center;
	text-align: left;
	padding: 0 40px;
}

.login-form {
	width: 100%;
	max-width: 380px;
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.login-form h2 {
	font-size: var(--text-xl);
	color: var(--color-dark);
}

.login-form h4 {
	font-size: var(--text-md);
	color: var(--color-accent);
	font-weight: var(--weight-thin);
	margin-top: var(--padding-md);
}

.input-wrapper label {
	color: #616161;
}

.password-input {
	position: relative;
}

.password-input svg {
	position: absolute;
	bottom: 12px;
	right: 12px;
	cursor: pointer;
}

.login-btn {
	width: 100%;
	height: 44px;
	border-radius: var(--radius-sm);
	color: var(--color-light);
	font-size: var(--text-lg);
	border: none;
	outline: none;
	background-image: linear-gradient(to right, #1e88e5, #42a5f5);
	position: relative;
}

.login-btn::after {
	width: 100%;
	height: 44px;
	border-radius: 6px;
	color: #ffffff;
	font-size: 16px;
	border: none;
	outline: none;
	display: grid;
	place-items: center;
	position: absolute;
	content: "Kirish";
	inset: 0;
	background-image: linear-gradient(to left, #1e88e5, #42a5f5);
	z-index: 1;
	opacity: 0;
	transition: opacity 0.2s linear;
}

.login-btn:hover::after {
	opacity: 1;
}

.login-btn:disabled {
	background: var(--bg-disabled);
	cursor: not-allowed;
}

@media (max-width: 980px) {
	.login-left {
		display: none;
	}

	.login-right {
		min-width: 0 !important;
		width: 100%;
		padding: 0;
	}

	.login-form {
		width: 300px;
	}
}
