.sidebar {
	width: 250px;
	height: calc(100dvh - 32px);
	border-radius: var(--radius-lg);
	box-shadow: var(--shadow-md);
	background-color: var(--bg-light);
	padding: 20px 0;
	text-align: center;
}
.sidebar.dark {
	background-color: var(--d-bg-light);
}

.sidebar h1 {
	font-size: var(--text-xxl);
	color: var(--color-dark);
	margin-bottom: 44px;
}
.sidebar.dark h1 {
	color: var(--d-color-dark);
}

.sidebar ul {
	height: calc(100dvh - 140px);
	overflow-y: auto;
	padding: 0;
	text-align: left;
}

.tabFocus {
	outline: var(--border-info);
}

.sidebar ul li a {
	width: 230px;
	height: 40px;
	text-decoration: none;
	display: grid;
	grid-template-columns: 40px auto;
	color: var(--color-secondary);
	padding: var(--padding-sm) var(--padding-lg);
	margin: 4px auto;
	border-radius: var(--radius-md);
	transition: 0.1s;
}
.sidebar.dark ul li a {
	color: var(--d-color-secondary);
}
.sidebar ul li a:hover {
	background-color: var(--bg-secondary);
	color: var(--color-light);
}
.sidebar.dark ul li a:hover {
	background-color: var(--d-bg-secondary);
}
.sidebar ul li a.active {
	background-color: var(--bg-secondary);
	color: var(--color-light);
}
.sidebar.dark ul li a.active {
	background-color: var(--d-bg-icon);
	color: var(--color-primary);
}

.logout {
	position: absolute;
	bottom: 30px;
	left: 30px;
	width: 226px;
	height: 40px;
}

.logout .user__info {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: auto 1fr auto;
}

.logout .user__image {
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 50%;
}

.logout .user__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.logout .user__about {
	height: 40px;
	padding: 0;
	margin: 0;
	padding-left: 10px;
	cursor: default;
}

.logout .user__about p {
	padding: 0;
	margin: 0;
	color: var(--color-secondary);
	font-size: var(--text-md);
}

.logout .user__about span {
	padding: 0;
	margin: 0;
	color: var(--color-accent);
	font-size: var(--text-sm);
}

.logout .user__info a {
	place-items: center;
	width: 40px;
	padding: 0;
	margin: 0;
	color: var(--color-danger);
}

.logout .user__info a:hover {
	color: var(--color-light);
	background-color: var(--bg-danger);
}
