.debt-switch {
	display: flex;
	flex-direction: row;
	padding: 4px;
	border-radius: var(--radius-lg);
	background-color: var(--bg-light);
	box-shadow: var(--shadow-md);
	margin-bottom: 20px;
	width: max-content;
}

.debt-switch.dark {
	background-color: var(--d-bg-light);
}

.debt-switch button {
	border: none;
	outline: none;
	background: none;
	color: var(--color-secondary);
	font-size: var(--text-md);
	padding: 4px 12px;
	border-radius: var(--radius-sm);
	transition: 0.1s;
	margin: 0 0.5px;
}

.debt-switch.dark button {
	color: var(--d-color-secondary);
}

.debt-switch button:hover {
	color: var(--color-primary);
	background: var(--bg-icon);
}

.debt-switch.dark button:hover {
	background: var(--d-bg-icon);
}

.debt-switch button:active {
	transform: scale(0.98);
	color: var(--color-primary);
	background: var(--bg-icon);
}

.debt-switch button.active {
	color: var(--color-primary);
}
