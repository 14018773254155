.product-table-btn {
	display: grid;
	place-items: center;
	width: 28px;
	height: 28px;
	border: none;
	outline: none;
	color: var(--color-light);
	background: var(--bg-danger);
	font-size: var(--text-md);
	border-radius: var(--radius-sm);
	transition: 0.1s;
}

.product-table-btn:hover {
	filter: brightness(0.95);
}

.product-table-btn:active {
	transform: scale(0.95);
}

.filter-wrapper.product {
	grid-template-columns: 200px 200px auto;
}

.table-item-edit-wrapper.product {
	height: 90px;
	margin-top: -30px;
}
