.card-item.clients {
	height: 170px;
}

.clients .card-item-top h3 {
	cursor: pointer !important;
	text-decoration: underline;
	text-decoration-color: var(--color-secondary);
	font-size: var(--text-modal);
	color: var(--color-secondary);
	transition: 0.1s;
}

.clients.dark .card-item-top h3 {
	color: var(--d-color-secondary);
	text-decoration-color: var(--d-color-secondary);
}

.clients .card-item-top h3:hover {
	color: var(--color-accent);
	text-decoration-color: var(--color-accent);
}

.clients.dark .card-item-top h3:hover {
	color: var(--d-color-input);
	text-decoration-color: var(--d-color-input);
}

.clients .card-item-bottom h4 {
	font-size: var(--text-md);
	color: var(--color-accent);
}

.clients.dark .card-item-bottom h4 {
	color: var(--d-color-accent);
}

.clients .card-item-bottom h5 {
	font-size: var(--text-sm);
	color: var(--color-accent);
}

.clients.dark .card-item-bottom h5 {
	color: var(--d-color-accent);
}

.clients .card-item-bottom button {
	color: var(--color-success);
	border: none;
	background: none;
	text-align: left;
	transition: 0.1s;
}

.clients.dark .card-item-bottom button {
	color: var(--color-primary);
}

.clients .card-item-bottom button:hover {
	filter: brightness(1.1);
}

/* client info */
.filter-wrapper.client-info {
	display: flex;
	flex-direction: row;
}

.client-info .input-wrapper {
	width: 240px;
}

.client-info-div {
	width: 100%;
	background-color: var(--bg-light);
	box-shadow: var(--shadow-md);
	border-top-left-radius: var(--radius-lg);
	border-top-right-radius: var(--radius-lg);
	padding: 30px 40px;
}

.client-info-div.dark {
	background-color: var(--d-bg-light);
}

.client-info-div h2 {
	color: var(--color-secondary);
	font-size: var(--text-xl);
}

.client-info-div.dark h2 {
	color: var(--d-color-secondary);
}

.client-info-div h3 {
	color: var(--color-accent);
	font-size: var(--text-lg);
	font-weight: var(--weight-thin);
	margin-top: 12px;
}

.client-info-div.dark h3 {
	color: var(--d-color-accent);
}

.client-info-div h3 span {
	color: var(--color-secondary);
}

.client-info-div.dark h3 span {
	color: var(--d-color-secondary);
}

.antd-collapse {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background-color: var(--bg-light);
}

.antd-collapse.dark {
	background-color: var(--d-bg-light);
	border: var(--border-invisible);
}

.antd-collapse-header {
	display: flex;
	justify-content: space-between;
}

.dark .antd-collapse-header {
	color: var(--d-color-secondary);
}

.antd-collapse.dark .ant-collapse-expand-icon,
.antd-collapse.dark .download-btn {
	color: var(--d-color-secondary);
}

.dark .ant-collapse-item {
	border: var(--d-border-primary);
	border-bottom: 0;
	border-left: 0;
	border-right: 0;
}

.ant-collapse-content-box {
	background: var(--bg-light);
	color: var(--color-secondary);
}

.dark .ant-collapse-content-box {
	background-color: var(--d-bg-neutral);
	color: var(--d-color-secondary);
}
