.my-collapsible {
	background-color: #777;
	color: white;
	cursor: pointer;
	padding: 18px;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
}
.collapseActive,
.my-collapsible:hover {
	background-color: #555;
}

.my-content {
	padding: 0 18px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.stat-filter-form {
	margin-bottom: 10px;
}

.stat-grid-wrapper {
	max-width: 100% !important;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 1rem;
}

.stat-col {
	box-sizing: border-box !important;
	background-color: #fff;
	border-radius: 12px;
	padding: 10px;
}

.stat-col h5 {
	font-weight: 600;
	font-style: normal;
	letter-spacing: 0.6px;
	font-size: 18px;
}

.stat-col:nth-child(1) {
	grid-column: 1/3;
	grid-row: 1/3;
}
.stat-col:nth-child(2) {
	grid-column: 3/5;
	grid-row: 1/3;
}
.stat-col:nth-child(3) {
	grid-row: 1/3;
}
.stat-col:nth-child(4) {
	grid-column: 1/2;
	grid-row: 3/5;
	max-width: 99.9% !important;
}
.stat-col:nth-child(5) {
	grid-column: 2/3;
	grid-row: 3/5;
	max-width: 99.9% !important;
}
.stat-col:nth-child(6) {
	grid-column: 3/6;
	grid-row: 3/6;
	max-width: 99.9% !important;
}
.stat-col:nth-child(7) {
	grid-column: 1/3;
	grid-row: 5/8;
}
.stat-col:nth-child(8) {
	grid-column: 3/5;
	grid-row: 6/8;
}

.stat-item-wrapper {
	display: flex;
	height: calc(100% - 30px);
}

.stat-item {
	margin-bottom: 5px;
	align-self: flex-end;
}

.stat-balance {
	display: flex;
	flex-direction: column;
}

.stat-balance h6 {
	font-size: 32px;
	font-weight: 600;
	font-style: normal;
	margin: 10px 0 30px 15px;
}

.stat-balance h6 span {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	color: #6c6c75;
}

.stat-table {
	width: 100%;
	text-align: left;
	color: #181829;
	font-size: 16px;
}

.tBodyMargin {
	height: 3px;
}
