.custom-pagination {
	float: left;
	margin: 10px 0;
	display: flex;
	margin-right: 10px;
}

.custom-pagination .wrapper {
	border-radius: 32px;
	background-color: var(--bg-pagination-btn);
}
.custom-pagination.dark .wrapper {
	background-color: var(--d-bg-light);
}

.custom-pagination button {
	width: 32px;
	height: 32px;
	border: none;
	outline: none;
	border-radius: 50%;
	color: var(--color-secondary);
	background-color: var(--bg-pagination-btn);
	font-size: var(--text-md);
	text-align: center;
	align-items: center;
	transition: 0.1s;
}
.custom-pagination.custom-pagination.dark button {
	color: var(--d-color-secondary);
	background-color: var(--d-bg-light);
}

.custom-pagination button:hover {
	color: var(--color-primary) !important;
	background-color: var(--bg-opacity) !important;
}
.custom-pagination.dark button:hover {
	background-color: var(--d-bg-secondary) !important;
}

.custom-pagination button.active {
	background-color: var(--bg-primary);
	color: var(--color-light);
}
.custom-pagination.dark button.active {
	background: var(--d-bg-icon);
	color: var(--color-primary);
}

.custom-pagination button.active:hover {
	pointer-events: none;
}

.custom-pagination button.next,
.custom-pagination button.previous {
	color: var(--bg-accent);
}

.custom-pagination button.previous {
	margin-right: 10px;
}

.custom-pagination button.next {
	margin-left: 10px;
}

.custom-pagination button:disabled {
	cursor: not-allowed !important;
}
.custom-pagination.dark button:disabled {
	background: var(--d-bg-light);
}

.custom-pagination button:disabled:hover {
	color: var(--bg-accent) !important;
	background-color: var(--bg-pagination-btn) !important;
}
.custom-pagination.dark button:disabled:hover {
	background: var(--d-bg-light) !important;
	color: var(--d-color-secondary);
}
